import WsProvider from './provider/WSProvider'

var serviceName = "webservis";
var moduleName = "firma";

var firmaService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    firmaDetay() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            firmaID: "1", // firmaid = 1 => plansor
            serviceName: moduleName,
            methodName: "firmaDetay"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    //$data['firmaID'],$data['firmaAdi'],$data['siteAdi'],$data['vergiDairesi'],$data['vergiNo'],$data['slogan'],$data['keyword'],$data['siteAdresi'],$data['siteAdresi2'],$data['renk'],$data['eposta'],$data['eposta2'],$data['telefon'],$data['sabitTelefon'],$data['dahili'],$data['fax'],$data['adres'],$data['erasmuscode'],$data['map'],$data['facebook'],$data['twitter'],$data['google'],$data['instagram'],$data['linkedin'],$data['youtube'],$data['swarm'],$data['whatsapp'],$data['skype'],$data['durum'],
    firmaGuncelleme(firmaID, firmaAdi, siteAdi, vergiDairesi, vergiNo, slogan, keyword, siteAdresi, siteAdresi2, renk, eposta, eposta2, telefon, sabitTelefon, dahili, fax, adres, koordinat, map, facebook, twitter, google, instagram, linkedin, youtube, swarm, whatsapp, skype, durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            firmaID: firmaID,
            firmaAdi,
            siteAdi,
            vergiDairesi,
            vergiNo,
            slogan,
            keyword,
            siteAdresi,
            siteAdresi2,
            renk,
            eposta,
            eposta2,
            telefon,
            sabitTelefon,
            dahili,
            fax,
            adres,
            koordinat,
            map,
            facebook,
            twitter,
            google,
            instagram,
            linkedin,
            youtube,
            swarm: swarm,
            whatsapp,
            skype,
            durum: durum,
            serviceName: moduleName,
            methodName: "firmaGuncelle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
};
export default firmaService